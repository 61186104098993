<template>
  <div
    class="color-picker"
    :id="`colorPicker${id}`"
    v-click-outside="closePicker"
  >
    <div
      :id="`color-picker-tooltip-target${id}`"
      @click="openPicker"
      class="color-circle"
      :style="`background: ${color}`"
    >
      <b-tooltip
        :target="`color-picker-tooltip-target${id}`"
        :delay="{ show: tooltipDelay, hide: 0 }"
      >
        Выбор цвета
      </b-tooltip>
    </div>
    <div
      class="picker-popup"
      :class="{ 'is-open': open, 'popup-left': direction === 'left' }"
    >
      <div
        class="popup-row"
        :class="{ 'flex-row-reverse': direction === 'left' }"
      >
        <div
          @click="closePicker"
          class="color-circle"
          :style="`background: ${validColor}`"
        ></div>
        <b-form-input
          v-model="tempColor"
          type="text"
          class="mx-1"
          :state="inputValid"
          @input="handleInput"
        />
        <b-button
          @click="selectColor"
          variant="flat-success"
          class="btn-icon p-0"
        >
          <feather-icon size="20" icon="CheckIcon" />
        </b-button>
      </div>
      <div class="sliders" :id="`sliderPicker${id}`"></div>
    </div>
  </div>
</template>

<script>
import iro from '@jaames/iro'
import { BFormInput, BButton, BTooltip } from 'bootstrap-vue'

export default {
  components: { BFormInput, BButton, BTooltip },
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tooltipDelay: 500,
    tempColor: '#006b5e',
    validColor: '',
    open: false,
    direction: 'right',
    id: null,
    sliderPicker: null,
    initialized: false,
    inputValid: undefined,
  }),
  methods: {
    openPicker() {
      this.open = true
      this.inputValid = undefined
      this.tempColor = this.color
      this.validColor = this.color
      if (!this.initialized) this.init()
      this.sliderPicker.color.hexString = this.tempColor
    },
    closePicker() {
      this.open = false
    },
    handleInput(value) {
      if (value.length != 7) {
        this.inputValid = false
        return
      }
      try {
        this.sliderPicker.color.hexString = value
        this.validColor = value
        this.inputValid = undefined
      } catch {
        this.inputValid = false
      }
    },
    checkPosition() {
      const right = document
        .querySelector(`#colorPicker${this.id}`)
        .getBoundingClientRect().right

      this.direction =
        right + 240 < document.documentElement.clientWidth ? 'right' : 'left'
    },
    selectColor() {
      this.$emit('selectColor', this.validColor)
      this.closePicker()
    },
    init() {
      this.checkPosition()
      const parent = document.querySelector(`#sliderPicker${this.id}`)
      const sliderPicker = new iro.ColorPicker(`#sliderPicker${this.id}`, {
        width: parent.clientWidth,
        color: 'rgb(255, 0, 0)',
        borderWidth: 1,
        borderColor: '#3b4253',
        margin: 2,
        handleRadius: 4,
        layout: [
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'hue',
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'value',
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'saturation',
            },
          },
        ],
      })
      sliderPicker.on(['color:change'], color => {
        this.tempColor = color.hexString
        this.validColor = color.hexString
      })

      this.sliderPicker = sliderPicker
      this.initialized = true
    },
  },
  mounted() {
    this.id = this._uid
    window.addEventListener('resize', this.checkPosition)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkPosition)
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.color-picker {
  position: relative;
  width: 30px;
  height: 30px;

  .color-circle {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border: 1px solid $input-border-color;
    border-radius: 1000px;
    cursor: pointer;
  }

  .picker-popup {
    position: absolute;
    z-index: 10;
    top: -14px;
    left: -10px;
    background: $white;
    width: 250px;
    border: 1px solid $input-border-color;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 10px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 0 10px 0 rgb(34 41 47 / 10%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .color-circle {
      margin-top: 4px;
    }

    .sliders {
      margin: 10px;
    }
  }

  .popup-left {
    left: -210px !important;
  }

  .is-open {
    visibility: visible;
    opacity: 1;
  }

  .popup-row {
    margin: 9px;
    display: flex;
  }
}

.dark-layout {
  .picker-popup {
    background: $theme-dark-input-bg;
    border-color: $theme-dark-border-color;
  }
  .color-circle {
    border-color: $theme-dark-border-color;
  }
}
</style>
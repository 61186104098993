



















































































































































































































import { Vue, Component } from 'vue-property-decorator'
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BvEvent,
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { upperCase } from '@/helpers/formatters'
import { webinarTagsStore } from '@/store'
import { IWebinarTag } from '@/interfaces/IWebinarTag'
import ColorPicker from '@/components/ColorPicker.vue'
import WayupSelect from '@/components/WayupSelect.vue'

@Component({
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    ColorPicker,
    WayupSelect,
    BRow,
    BCol,
  },
})
export default class WebinarTagEditModal extends Vue {
  state: 'create' | 'edit' = 'create'
  pending = false

  id: number | null = null
  label = ''
  tagColor = ''
  backgroundColor = ''
  backgroundHoverColor = ''
  circleColor = ''
  backgroundGradientColor = ''
  circleFigureColor = ''
  triangleFirstFigureColor = ''
  triangleSecondFigureColor = ''
  circlePosition: { label: string; value: string } | null = null

  errors: { [key: string]: string | null } = {
    label: null,
    tagColor: null,
    backgroundColor: null,
    backgroundHoverColor: null,
    circleColor: null,
    backgroundGradientColor: null,
    circleFigureColor: null,
    triangleFirstFigureColor: null,
    triangleSecondFigureColor: null,
    circlePosition: null,
  }

  circlePositions: Array<{ label: string; value: string }> = [
    { label: 'Сверху слева', value: 'tl' },
    { label: 'Сверху справа', value: 'tr' },
    { label: 'Снизу слева', value: 'bl' },
    { label: 'Снизу справа', value: 'br' },
  ]

  get modalTitle() {
    return this.state === 'create' ? 'Создание тега' : 'Редактирование тега'
  }

  get okTitle() {
    return this.state === 'create' ? 'Создать' : 'Сохранить'
  }

  get upperCaseFormatter() {
    return upperCase
  }

  async show(id?: number) {
    this.$bvModal.show('webinar-tag-edit-modal')

    if (id) {
      this.pending = true
      this.state = 'edit'
      const tag = await webinarTagsStore.fetchTag(id)
      if (tag) {
        this.fillData(tag)
      } else {
        this.$bvModal.hide('webinar-tag-edit-modal')
      }
      this.pending = false
    } else {
      this.state = 'create'
    }
  }

  fillData(tag: IWebinarTag) {
    this.id = tag.id!
    this.label = tag.label
    this.tagColor = tag.colors.tag
    this.backgroundColor = tag.colors.background
    this.backgroundHoverColor = tag.colors.backgroundHover
    this.circleColor = tag.colors.circle
    this.backgroundGradientColor = tag.colors.backgroundGradient
    this.circleFigureColor = tag.colors.figures.circle
    this.triangleFirstFigureColor = tag.colors.figures.triangleFirst
    this.triangleSecondFigureColor = tag.colors.figures.triangleSecond
    this.circlePosition =
      this.circlePositions.find(p => p.value === tag.circlePosition) || null
  }

  async handleOk(e: BvEvent) {
    if (!this.validate()) {
      return e.preventDefault()
    }
    this.pending = true

    const tag = {
      label: this.label,
      circlePosition: this.circlePosition!.value,
      colors: {
        tag: this.tagColor,
        background: this.backgroundColor,
        backgroundHover: this.backgroundHoverColor,
        circle: this.circleColor,
        backgroundGradient: this.backgroundGradientColor,
        figures: {
          circle: this.circleFigureColor,
          triangleFirst: this.triangleFirstFigureColor,
          triangleSecond: this.triangleSecondFigureColor,
        },
      },
    }

    switch (this.state) {
      case 'create':
        if (!(await webinarTagsStore.addTag(tag))) {
          e.preventDefault
        }
        break

      case 'edit':
        if (!(await webinarTagsStore.updateTag({ tag, id: this.id! }))) {
          e.preventDefault
        }
        break
    }

    this.pending = false
  }

  resetErrors() {
    for (const key in this.errors) {
      this.errors[key] = null
    }
  }

  validate() {
    this.resetErrors()

    if (!this.label) {
      this.errors.label = 'Заполните поле'
    }
    if (!this.tagColor) {
      this.errors.tagColor = 'Заполните поле'
    }
    if (!this.backgroundColor) {
      this.errors.backgroundColor = 'Заполните поле'
    }
    if (!this.backgroundHoverColor) {
      this.errors.backgroundHoverColor = 'Заполните поле'
    }
    if (!this.circleColor) {
      this.errors.circleColor = 'Заполните поле'
    }
    if (!this.backgroundGradientColor) {
      this.errors.backgroundGradientColor = 'Заполните поле'
    }
    if (!this.circleFigureColor) {
      this.errors.circleFigureColor = 'Заполните поле'
    }
    if (!this.triangleFirstFigureColor) {
      this.errors.triangleFirstFigureColor = 'Заполните поле'
    }
    if (!this.triangleSecondFigureColor) {
      this.errors.triangleSecondFigureColor = 'Заполните поле'
    }
    if (!this.circlePosition) {
      this.errors.circlePosition = 'Выберите расположение'
    }

    for (const key in this.errors) {
      const error = this.errors[key]
      if (error) {
        return false
      }
    }
    return true
  }

  reset() {
    this.resetErrors()
    this.id = null
    this.label = ''
    this.tagColor = ''
    this.backgroundColor = ''
    this.backgroundHoverColor = ''
    this.circleColor = ''
    this.backgroundGradientColor = ''
    this.circleFigureColor = ''
    this.triangleFirstFigureColor = ''
    this.triangleSecondFigureColor = ''
    this.pending = false
    this.circlePosition = null
  }
}

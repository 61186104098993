










import { Vue, Component, Ref } from 'vue-property-decorator'
import { BButton, BCard } from 'bootstrap-vue'
import WebinarTagEditModal from '@/components/modals/WebinarTagEditModal.vue'
import TagsTable from '@/components/webinars/TagsTable.vue'

@Component({
  components: { BButton, BCard, WebinarTagEditModal, TagsTable },
})
export default class WebinarTags extends Vue {
  @Ref('webinarTagEditModal') readonly webinarTagEditModal!: {
    show: (id?: number) => void
  }

  edit(id: number) {
    this.webinarTagEditModal.show(id)
  }

  addTag() {
    this.webinarTagEditModal.show()
  }
}

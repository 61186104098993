












































import { Vue, Component, Ref, Emit } from 'vue-property-decorator'
import { BButton, BBadge } from 'bootstrap-vue'
import { webinarTagsStore } from '@/store'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue'
const { VueGoodTable } = require('vue-good-table')
import '@core/scss/vue/libs/vue-good-table.scss'

@Component({
  components: { BButton, VueGoodTable, BBadge, DeleteConfirmationModal },
})
export default class WebinarTagsTable extends Vue {
  @Ref('deleteModal') readonly deleteConfirmationModal!: {
    show: (title: string) => Promise<boolean>
  }

  columns: Array<{
    field: string
    label: string
    type?: string
    sortable?: boolean
    width?: string
  }> = [
    {
      field: 'label',
      label: 'Название',
      width: '100%',
    },
    {
      field: 'color',
      label: 'Цвет',
    },
    {
      field: 'buttons',
      label: '',
      sortable: false,
    },
  ]

  get tags() {
    return webinarTagsStore.tags
  }

  async mounted() {
    await webinarTagsStore.fetchTags()
  }

  async remove(id: number, label: string) {
    if (await this.deleteConfirmationModal.show(label)) {
      await webinarTagsStore.deleteTag(id)
    }
  }

  @Emit()
  edit(id: number) {}
}
